import { Tooltip } from "@mui/material";
import React from "react";

function CustomizedTooltip(props) {
  const { text,  position } = props;
  return (
    <Tooltip
      title={<span className="h3Regular">{text}</span>}
      placement={position ? position : "right"}
      arrow
      disableInteractive
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#15284B',
            '& .MuiTooltip-arrow': {
              color: '#15284B'
            }
          }
        },
        popper: {
          sx: {
           zIndex: 110000 + '!important'
          }
         }
      }}
    >
      {/* <Button>top-start</Button> */}
      {props.children}
    </Tooltip>
  );
}

export default CustomizedTooltip;

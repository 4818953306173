import { SubmissionActionTypes } from '../constants/submissionActionTypes';

const initialState = {
    submission: {},
    expandedRows: [],
    currentPage: 1
}

const submissionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SubmissionActionTypes.SET_SELECTED_SUBMISSION:
            return { ...state, submission: payload };

        case SubmissionActionTypes.REMOVE_SELECTED_SUBMISSION:
            return { ...state, submission: {} };

        case SubmissionActionTypes.SUBMISSIONS_ERROR:
            return { ...state, submissions: {} };

        case SubmissionActionTypes.SET_CURRENT_PAGE:
            return { ...state, currentPage: payload };
        
        case SubmissionActionTypes.SET_EXPANDED_ROWS:
            return { ...state, expandedRows: payload };

        default:
            return state;
    }
}

export default submissionsReducer
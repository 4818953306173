import React from 'react';
import './Unauthorized.css';

const Unauthorized = () => {
  return (
    <div>
      Unauthorized
    </div>
  )
}

export default Unauthorized

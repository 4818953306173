import { HitlStatus } from '../constants/constants';
import DOMPurify from 'dompurify';

export function isEmpty(obj = {}) {
    return Object.keys(obj).length === 0
}

export function isString(value) {
    return typeof value === 'string' || value instanceof String
}

export function isNumber(value) {
    return typeof value == 'number' && !isNaN(value)
}

export function isBoolean(value) {
    return value === true || value === false
}

export function isNil(value) {
    return typeof value === 'undefined' || value === null
}

export function isDateString(value) {
    if (!isString(value)) return false

    return value.match(/^\d{2}-\d{2}-\d{4}$/)
}

export function convertDateString(value) {
    return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2)
}

export function toLower(value) {
    if (isString(value)) {
        return value.toLowerCase()
    }
    return value
}

export function convertType(value) {
    if (isNumber(value)) {
        return value.toString()
    }

    if (isDateString(value)) {
        return convertDateString(value)
    }

    if (isBoolean(value)) {
        return value ? '1' : '-1'
    }

    return value
}

export function titlecase(input) {
    return input?.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));;
}

export function removeUnderscore(input) {
    return input.replaceAll('_', ' ');
}

export function roundToTwo(num) {
    return +(Math.round(num + 'e+2') + 'e-2');
}

export function filterRows(rows, filters) {
    if (isEmpty(filters)) return rows

    return rows.filter((row) => {
        return Object.keys(filters).every((accessor) => {
            const value = row[accessor]
            const searchValue = filters[accessor]

            if (isString(value)) {
                return toLower(value).includes(toLower(searchValue))
            }

            if (isBoolean(value)) {
                return (searchValue === 'true' && value) || (searchValue === 'false' && !value)
            }

            if (isNumber(value)) {
                return value === searchValue
            }

            return false
        })
    })
}

export function sortRows(rows, sortBy, orderBy) {
    if (orderBy === 'asc') {
        return rows.sort(
            (a, b) => 0 - (a[sortBy] > b[sortBy] ? -1 : 1)
        );
    } else {
        return rows.sort(
            (a, b) => 0 - (a[sortBy] > b[sortBy] ? 1 : -1)
        );
    }
}

export function paginateRows(sortedRows, activePage, rowsPerPage) {
    return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
}

export function formatNumberToPercentage(number) {
    return `${(number * 100).toFixed(2)}%`
}

export function capitalizeFirstLetter(inputString) {
    if (!inputString) {
        return inputString;
    }

    const words = inputString.split(' ');
    const wordArr = words.map(word => word[0].toUpperCase() + word.slice(1).toLowerCase());
    return wordArr.join(' ');
}

export function cleanString(inputString) {
    if (!inputString) {
        return inputString;
    }
    const cleanedString = inputString.replace(/[^a-z0-9]+/gi, '');
    return cleanedString;
}

export function sanitizeString(inputString) {
    if (!inputString) {
        return inputString;
    }
    const cleanedString = inputString.replace(/[:"]/gi, '');
    return cleanedString.trimEnd();
}

export function omitProperties(obj, propertyList) {
    let updatedObj = Object.assign({}, obj);
    for (let property of propertyList) delete updatedObj[property];

    return updatedObj;
}

export function validateDescription(value) {
    if (value?.trim()?.length === 0 || value?.length < 5 || value?.length > 200 || value.search(/^[a-zA-Z0-9: _-]+$/i) === -1) {
        return false;
    }
    return true;

}
export function validateNumber(value) {
    if (value.search(/^\d+$/) === -1 || value?.trim()?.length === 0) {
        return false;
    }
    return true;
}

export function formattedReviewSeconds(totalSeconds, skipZeros = false) {
    const values = formattedTime(totalSeconds);

    const hours = +values[0];
    const minutes = +values[1];
    const secs = +values[2];

    let hoursTemp;
    let minutesTemp;
    let secsTemp;

    if (!skipZeros) {
        hoursTemp = `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
        minutesTemp = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
        secsTemp = `${secs} ${secs === 1 ? 'sec' : 'secs'}`;
    } else {
        hoursTemp = hours !== 0 ? `${hours} ${hours === 1 ? 'hr' : 'hrs'}` : '';
        minutesTemp = minutes !== 0 ? `${minutes} ${minutes === 1 ? 'min' : 'mins'}` : '';
        secsTemp = `${secs} ${secs === 1 ? 'sec' : 'secs'}`;
    }

    return `${hoursTemp} ${minutesTemp} ${secsTemp}`;
}

export function formattedTime(totalSeconds) {
    const totalMs = totalSeconds * 1000;
    const result = new Date(totalMs).toISOString().slice(11, 19);
    const values = result.split(':');

    return values;
}

export function hitlShouldShow(allowHitl) {
    if (allowHitl !== HitlStatus.HIDE) {
        return true;
    }
    return false;
}

export function hitlShouldEnable(allowHitl) {
    if (allowHitl === HitlStatus.ENABLE) {
        return true;
    }
    return false;
}

export function hitlShouldEnableOrHide(allowHitl) {
    if (allowHitl === HitlStatus.ENABLE || allowHitl === HitlStatus.HIDE) {
        return true;
    }
    return false;
}

export function hitlShouldDisable(allowHitl) {
    if (allowHitl === HitlStatus.DISABLE) {
        return true;
    }
    return false;
}

export function hitlShouldDisableOrHide(allowHitl) {
    if (allowHitl === HitlStatus.DISABLE || allowHitl === HitlStatus.HIDE) {
        return true;
    }
    return false;
}

export function sanitizeInput(userInput) {
    return DOMPurify.sanitize(userInput);
}

export function emailValidation(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
}

export function makeid(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
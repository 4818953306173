import React from 'react';
import { axiosPrivate } from '../config/axios'
import { useUserAuth } from '../context/UserAuthContext';
import { useLocation, useNavigate } from "react-router-dom";

const useAxios = () => {

    const { user, loaded, logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (!loaded) {
            return;
        }

        const requestIntercept = axiosPrivate.interceptors.request.use(async config => {
            const token = await user.getIdToken();
            if (!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        }, (error) => Promise.reject(error));

        const responseIntercept = axiosPrivate.interceptors.response.use(response => response,
            async (error) => {
                const previousRequest = error?.config;
                if (error?.response?.status === 403 && !previousRequest?.sent) {
                    previousRequest.sent = true;
                    // Used for token refresh
                    // const newAccessToken = await refresh();
                    // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    // return axiosPrivate(prevRequest);
                }

                if (error?.response?.status === 401) {
                    await logOut();
                    navigate("/signin", { state: { from: location } });
                }

                return Promise.reject(error);
            })

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, user, loaded)

    return axiosPrivate;
}

export default useAxios
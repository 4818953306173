import { TableContainer } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledTableContainer = withStyles(() => ({
  root: {
    display: 'block',
    overflow: 'auto',
  },
}))(TableContainer);

export const StyledSectionTableContainer = withStyles(() => ({
  root: {
    display: 'block',
    overflow: 'auto',
    borderBottom: '2px solid #8992A1 !important',
  },
}))(TableContainer);
import { TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#E1E2E2 !important',
    color: '#15284B !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    lineHeight: '22px !important',
    letterSpacing: '0.4px !important',
    height: '2.5rem !important',
    padding: '0.2rem !important',
    paddingLeft: '0.5rem !important',
    borderRight: '1px solid #D4D5D8 !important',
    whiteSpace: 'nowrap !important',
    '&:first-child': {
      paddingLeft: '1.25rem !important',
    },
  },
  body: {
    color: '#15284B !important',
    background: 'white !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    lineHeight: '19px !important',
    letterSpacing: '0.4px !important',
    height: '3.25rem !important',
    backgroundClip: 'padding-box !important',
    padding: '0.2rem !important',
    paddingLeft: '0.5rem !important',
    whiteSpace: 'normal !important',
    '&:first-child': {
      // paddingLeft: '1rem',
    },
  },
}))(TableCell);

export const NestedTableCell = withStyles(() => ({
  head: {
    borderRight: '1px solid #D4D5D8 !important',
    borderBottom: 'none !important',
    background: '#F1F3F4 !important',
    '&:first-child': {
      paddingLeft: '0 !important',
    },
    '&:last-child': {
      paddingRight: '0 !important',
    },
  },
  body: {
    color: '#15284B !important',
    background: 'white !important',
    borderBottom: '1px solid #F5F8FA !important',
    '&:first-child': {
      paddingLeft: '1.25rem !important',
    },
    '&:last-child': {
      paddingRight: '0 !important',
    },
  },
}))(StyledTableCell);

export const InnerTableCell = withStyles(() => ({
  head: {
    borderRight: '1px solid #D4D5D8 !important',
    borderBottom: 'none !important',
    background: '#F1F3F4 !important',
    '&:first-child': {
      paddingLeft: '0 !important',
    },
    '&:last-child': {
      paddingRight: '0 !important',
    },
  },
  body: {
    color: '#15284B !important',
    background: 'white !important',
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '&:first-child': {
      paddingLeft: '1.25rem !important',
    },
    '&:last-child': {
      paddingRight: '0 !important',
    },
  },
}))(StyledTableCell);

export const StyledTableCellTerms = withStyles(() => ({
  head: {
    backgroundColor: 'rgba(0, 0, 0, 0.06) !important',
    color: '#000000 !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    fontWeight: 500,
    lineHeight: '22px !important',
    letterSpacing: '0.4px !important',
    height: '2.5rem !important',
    padding: '0.2rem !important',
    paddingLeft: '0.5rem !important',
    borderRight: '1px solid #B9B9B9 !important',
    borderBottom: '1px solid #B9B9B9 !important',
    whiteSpace: 'nowrap !important',
    '&:first-child': {
      // paddingLeft: '1.25rem !important',
    },
  },
  body: {
    color: '#15284B !important',
    background: 'rgba(255, 255, 255, 0.002) !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    lineHeight: '19px !important',
    letterSpacing: '0.4px !important',
    height: '3.25rem !important',
    backgroundClip: 'padding-box !important',
    padding: '0.2rem !important',
    paddingLeft: '0.5rem !important',
    borderRight: '1px solid #B9B9B9 !important',
    '&:first-child': {
      // paddingLeft: '1rem',
    },
  },
}))(TableCell);
export const ExtractionTableCell = withStyles(() => ({
  head: {
    color: '#15284B !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    lineHeight: '14px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem !important',
    borderBottom: '1px solid #AEB3BC !important',
    whiteSpace: 'normal !important',
  },
  body: {
    color: '#3C4C68 !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    lineHeight: '16px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem !important',
    borderBottom: '1.5px solid #8992A1 !important',
    whiteSpace: 'normal !important',
  }
}))(TableCell);

export const ExtractionSectionsTopTableCell = withStyles(() => ({
  body: {
    color: '#3C4C68 !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    fontWeight: '500 !important',
    lineHeight: '16px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem !important',
    borderBottom: '0.5px solid #D4D5D8 !important',
    whiteSpace: 'normal !important',
  }
}))(TableCell);

export const ExtractionSectionsTableCell = withStyles(() => ({
  body: {
    color: '#3C4C68 !important',
    fontFamily: 'sans-serif !important',
    fontSize: '0.875rem !important',
    lineHeight: '16px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem !important',
    borderBottom: '0.5px solid #D4D5D8 !important',
    whiteSpace: 'normal !important',
  }
}))(TableCell);

// comparison screen cells
// for all right cells with dynamic color neutrl, incorrect etc
export const ComparisonTableCell = withStyles(() => ({
  body: {
    fontFamily: 'sans-serif !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem 0.25rem 0.25rem !important',
    whiteSpace: 'normal !important',
    minWidth: '5rem !important',
    wordBreak: 'break-word !important',
    // maxWidth: '33% !important'
  }
}))(TableCell);

// for all left table cells
export const ComparisonLeftTableCell = withStyles(() => ({
  body: {
    color: '#15284B !important',
    fontFamily: 'sans-serif !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem 0.25rem 0.25rem !important',
    whiteSpace: 'normal !important',
    width: '10rem !important',
    maxWidth: '10rem !important',
    wordWrap: 'break-word !important'
  }
}))(TableCell);

// for top header and first row left key ex-Key
export const ComparisonHeaderTableCell = withStyles(() => ({
  head: {
    color: '#15284B !important',
    fontFamily: 'sans-serif !important',
    fontSize: '10px !important',
    fontWeight: '500 !important',
    lineHeight: '14px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem !important',
    whiteSpace: 'normal !important',
  },
  body: {
    color: '#15284B !important',
    fontFamily: ' sans-serif !important',
    fontSize: '10px !important',
    fontWeight: '500 !important',
    lineHeight: '14px !important',
    letterSpacing: '0.4px !important',
    padding: '0.25rem 0.5rem !important',
    whiteSpace: 'normal !important',
  }
}))(TableCell);

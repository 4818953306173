import { ComparisonActionTypes } from "../constants/comparisonActionTypes";

const initialState = {
  selectedDocumentIds: [],
};

const comparisonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ComparisonActionTypes.SET_SELECTED_DOCUMENT_IDS:
      return { ...state, selectedDocumentIds: payload };

    case ComparisonActionTypes.REMOVE_SELECTED_DOCUMENT_IDS:
      return { ...state, selectedDocumentIds: [] };

    default:
      return state;
  }
};

export default comparisonReducer;

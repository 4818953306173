import React from 'react';
import './MainLayout.css';
import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import ChatBot from '../../components/chat-bot/ChatBot';
import DraggableButton from '../../components/draggable-button/DraggableButton';
import IdleSessionTimeout from '../../components/idle-session-timeout/IdleSessionTimeout';

const MainLayout = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterOpen = Boolean(anchorEl);

    const handleFilterClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            {/* <Sidebar /> */}
            <Header />
            <section className='home-section'>
                <div id='mt-router-outlet-main'>
                    <Outlet />
                </div>
            </section>
            {/* <DraggableButton handleClick={(event) => setAnchorEl(event.currentTarget)} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={filterOpen}
                onClose={handleFilterClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '& .MuiList-root': {
                        padding: 0
                    },
                    '& .MuiMenuItem-root': {
                        padding: 0
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <ChatBot />
            </Menu> */}
            <IdleSessionTimeout />
        </React.Fragment>
    )
}

export default MainLayout

import { combineReducers } from 'redux';
import submissionsReducer from './submissionsReducer';
import timerReducer from './timerReducer';
import extractionReducer from './extractionReducer';
import comparisonReducer from './comparisonReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
    submissions: submissionsReducer,
    timer: timerReducer,
    extraction: extractionReducer,
    comparison: comparisonReducer,
    user: userReducer,
})

export default reducers;

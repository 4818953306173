import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    color: '#2D4081',
    borderRadius: '0.25rem',
    textTransform: 'none',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E8F0FB',
    },
    '&:active': {
      backgroundColor: '#C2DBFF',
    },
  },
  disabledButtonStyle: {
    color: '#2D4081',
    borderRadius: '0.25rem',
    textTransform: 'none',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'not-allowed',
    backgroundColor: '#E4E6EF',
  },
  logoText: {
    display: 'flex',
  },
  logo: {
    marginRight: '0.5rem',
  },
}));
function ButtonOutlined(props) {
  const {
    text, onClick, icon, borderColor = '#AEB3BC', textColor = '#3C4C68', disabled, width = '', height = '', marginRight = '', imageMargin = ''
  } = props;
  const classes = useStyles();

  return (
    <div>
      <button type="button" style={{ border: `1px solid ${borderColor}`, width: width, height: height, marginRight: marginRight }} className={disabled ? classes.disabledButtonStyle : classes.buttonStyle} onClick={onClick} disabled={disabled}>
        <span className={`${classes.logoText}`}>
          {icon && <img className={`${classes.logo}`} src={icon} alt="logo" style={{ marginInline: imageMargin }} />}
          <span style={{ color: textColor, fontWeight: '500', }}>{text}</span>
        </span>
      </button>
    </div>
  );
}
export default ButtonOutlined;

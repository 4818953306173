
import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';

import Routes from './routes/Routes';

function App() {
  return (
    <div className='App'>
      <ToastContainer autoClose={2500} />
      <Routes />
    </div>
  );
}

export default App;

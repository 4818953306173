// import * as pdfjsLib from "pdfjs-dist/build/pdf";
import * as pdfjsLib from "pdfjs-dist";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const makeThumbnail = (page) => {
  // draw page to fit into input size canvas
  // const desiredWidth = size;
  const viewPort = page.getViewport({ scale: 1 });

  // const scale = desiredWidth / viewPort.width;
  // const scaledViewport = page.getViewport({ scale: scale });
  const scale = 2; 
  const scaledViewport = page.getViewport({ scale });
  const canvas = document.createElement("canvas");
  canvas.height = scaledViewport.height;
  canvas.width = scaledViewport.width;

  return page
    .render({
      canvasContext: canvas.getContext("2d"),
      viewport: scaledViewport,
    })
    .promise.then(function () {
      return canvas.toDataURL();
    });
};

const getThumbnails = (url) => {
  return pdfjsLib.getDocument(url).promise.then(function (doc) {
    var pages = [];
    while (pages.length < doc.numPages) pages.push(pages.length + 1);
    return Promise.all(
      pages.map((num) =>
        doc
          .getPage(num)
          .then((page) => makeThumbnail(page))
          .then((thumbnail) => ({
            page: num, src: thumbnail, title: `Page ${num}`
          }))
      )
    );
  });
}

export default getThumbnails;
import React from 'react';
import useIdleTimeoutV2 from "../../hooks/useIdleTimeoutV2";
import { useUserAuth } from '../../context/UserAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const IDLE_TIME_LIMIT = 60 // in minutes

const IdleSessionTimeout = () => {
  const { logOut } = useUserAuth();
  const { isUserIdle } = useIdleTimeoutV2(IDLE_TIME_LIMIT * 60000); // 30 minutes
  const navigate = useNavigate();
  const location = useLocation();

  const logoutUser = async () => {
    try {
      await logOut();
      navigate("/signin", { state: { from: location } });
    } catch (error) {
      console.log(error);
    }
  };

  // logging out user if screen is idle for 30 minutes
  React.useEffect(() => {
    if (isUserIdle) {
      logoutUser();
    }
  }, [isUserIdle]);

  // for logging out if last activity was 30 minutes back and tab is closed
  React.useEffect(() => {
    const newTime = Date.now();
    if (localStorage.getItem('loggedInTime')) {
      const difference = ((newTime - localStorage.getItem('loggedInTime')) / 60000);
      if (difference > IDLE_TIME_LIMIT) {
        logoutUser();
      }
    }
  }, []);

  // setting the timer when tab is getting closed
  const setTimerBeforeUnload = () => {
    localStorage.setItem('loggedInTime', Date.now());
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      return setTimerBeforeUnload();
    })
  }, []);
  
  return (
    <></>
  )
}

export default IdleSessionTimeout;

import { UserActionTypes } from "../constants/userActionTypes"

const initialState = {
    userId: '',
};

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UserActionTypes.SET_USER_ID:
            return { ...state, userId: payload };

        default:
            return state;
    }
}

export default userReducer;

import React from 'react';
import './Loader.css';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = ({ loading, sideDrawer = false }) => {
  return (
    <div>
      {sideDrawer ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress 
            thickness={3}
            color="success"
         />
        </Box>
      ) : (
        <div>
          <BlockUI
            blocked={loading}
            fullScreen
            template={
              <ProgressSpinner
                style={{ width: '40px', height: '40px' }}
                strokeWidth="3"
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default Loader;

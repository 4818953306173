import React from 'react';
import IconButton from '@mui/material/IconButton';
import ChatbotLogo from '../../assets/chatbotLogo.svg';

function DraggableButton({ handleClick }) {
  return (
    <IconButton
      size="small"
      disableRipple
      onClick={handleClick}
      sx={{
        position: 'absolute', bottom: 20, right: 20
      }}
    >
      <img src={ChatbotLogo}
        alt="delete"
        aria-hidden="true"
        style={{ zIndex: 20 }}
      />
    </IconButton>
  );
}

export default DraggableButton;

const SubmissionStatus = {
  Received: "received",
  Processing: "processing",
  Under_review: "under_review",
  Reviewed: "reviewed",
  Ready_for_comparison: "ready_for_review",
};

const AttachmentStatus = {
  Received: "received",
  Classifying: "classifying",
  Classified: "classified",
  Extracting: "extracting",
  Processed: "processed",
  Reviewed: "reviewed",
};

const DeploymentStatus = {
  Pending: 'Pending',
  Deployed: 'Deployed',
}

const BusinessCase = {
  Renewal: "Renewal",
  Automated_Renewal: "Automated Renewal",
  Renewal_Across_Different_Carrier: "Renewal across different carrier",
  Invalid: "Invalid",
  New_Business: "New Business",
};

const LOBSummary = {
  Contains_General_Liability: "Contains General Liability",
  One_or_more_documents_do_not_contain_General_Liability:
    "One or more documents do not contain General Liability",
};

const CarrierTypes = [
  {
    key: "Travelers",
    value: "Travelers",
  },
  {
    key: "Chubb",
    value: "Chubb",
  },
  {
    key: "Zurich",
    value: "Zurich",
  },
  {
    key: "Other",
    value: "Other",
  },
];

const PolicyTermKeys = {
  POLICY_TERM_START_DATE: 'policy term start date',
  POLICY_TERM_END_DATE: 'policy term end date',
}

const GroupNames = {
  FIRST_NAMED_INSURED: "First Named Insured",
  MAILING_ADDRESS: "Mailing Address",
  NAMED_INSURED_SCHEDULE: "Named Insured Schedule / Additional Named Insureds",
  POLICY_TERM: "Policy Term",
  POLICY_FORMS_LISTED_AND_DESCRIPTION: "Policy Forms Listed & Description",
  BINDER_FORMS_LISTED: "Binder Forms Listed",
  POLICY_FORMS_ATTACHED: "Policy Forms Attached",
  TIME_AND_PLACE_OF_EXPIRY: "Time and Place of Expiry",
  POLICY_NUMBER: "Policy Number",
  CARRIER_NAME: "Carrier Name",
  ISSUING_PAPER: "Issuing Paper",
  PRODUCER_NAME: "Producer Name",
  LIMITS_SUBLIMITS: "Limits / Sublimits",
  RETENTIONS_DEDUCTIBLES: "Retentions / Deductibles",
  PREMIUM_INCLUDING_TAXES_AND_SURCHARGES:
    "Premium (including Taxes and Surcharges)",
  CURRENCY: "Currency",
  COMMISSION_DECLARATIONS: "Commission (Declarations)",
  DAYS_NOTICE_OF_CANCELLATION: "Days Notice of Cancellation",
  RETRO_CONTINUITY_DATE: "Retro / Continuity Date",
  LOCATION_SCHEDULE: "Location Schedule",
  ADDITIONAL_INTERESTS: 'Additional Interests / Loss Payees / Additional Insured',
  POLICY_FORM_CLAIMS_MADE_OCCURRENCE_OR_DISCOVERY_BASIS:
    "Policy Form (Claims Made, Occurrence or Discovery Basis)",
  POLICY_TERM_ON_ENDORSEMENTS: "Policy Term on Endorsements",
  POLICY_EFFECTIVE_DATE_ON_ENDORSEMENTS: 'Policy Effective Date On Endorsements (if Applicable)',
  POLICY_NUMBER_ON_ENDORSEMENTS: 'Policy Number On Endorsements',
  POLICY_FORMS_LISTED: 'Policy Forms Listed',
};

const keysMapping = {
  NAMED_INSURED_SCHEDULE: "Named Insured",
  POLICY_TERM_ON_ENDORSEMENTS: "Endorsements Number",
  POLICY_EFFECTIVE_DATE_ON_ENDORSEMENTS: 'Endorsements Number',
  POLICY_NUMBER_ON_ENDORSEMENTS: 'Endorsements Number',
  LIMITS_SUBLIMITS: "Description",
  RETENTIONS_DEDUCTIBLES: "Description",
  POLICY_FORMS_ATTACHED: "Form/Endorsement Number",
  POLICY_FORMS_LISTED_AND_DESCRIPTION: "Form/Endorsement Number",
  BINDER_FORMS_LISTED: "Form/Endorsement Number",
  LOCATION_SCHEDULE: "Location",
  ADDITIONAL_INTERESTS: 'Description'
};

const SectionNames = {
  EMPTY: '',
  LIMITS_CGL: 'Limits / Sublimits [CGL]',
  LIMITS_EBL: 'Limits / Sublimits [EBL]',
  RETENTIONS_CGL: 'Retentions / Deductibles [CGL]',
  RETENTIONS_EBL: 'Retentions / Deductibles [EBL]',
  LOCATION_SCHEDULE: 'Location Schedule',
  ENDORSEMENT_LIMITS: 'Endorsement Limits',
  BINDER_FORMS_LISTED: 'Binder Forms Listed',
  BINDER_FORMS_CGL: 'Binder Forms Listed [CGL]',
  BINDER_FORMS_EBL: 'Binder Forms Listed [EBL]',
  POLICY_FORMS_LISTED: 'Policy Forms Listed',
  POLICY_FORMS_LISTED_CGL: 'Policy Forms Listed [CGL]',
  POLICY_FORMS_LISTED_EBL: 'Policy Forms Listed [EBL]',
  NAMED_INSURED_SCHEDULE: 'Named Insured Schedule / Additional Named Insureds',
  ADDITIONAL_INTERESTS: 'Additional Interests / Loss Payees / Additional Insured',
  POLICY_TERM_ON_ENDORSEMENTS: 'Policy Term on Endorsements',
  POLICY_NO_ON_ENDORSEMENTS: 'Policy Number on Endorsements',
  POLICY_EFFECTIVE_DATE_ON_ENDORSEMENTS: 'Policy Effective Date On Endorsements (if applicable)',
  POLICY_FORMS_ATTACHED: 'Policy Forms Attached',
  POLICY_FORMS_ATTACHED_CGL: 'Policy Forms Attached [CGL]',
  POLICY_FORMS_ATTACHED_EBL: 'Policy Forms Attached [EBL]',
  POLICY_FORMS_LISTED_AND_DESCRIPTION: 'Policy Forms Listed & Description',
  POLICY_FORMS_LISTED_AND_DESCRIPTION_CGL: 'Policy Forms Listed & Description [CGL]',
  POLICY_FORMS_LISTED_AND_DESCRIPTION_EBL: 'Policy Forms Listed & Description [EBL]',
  DAYS_NOTICE_OF_CANCELLATION: 'Days Notice of Cancellation',
};

const KeysForReviseRules = [
  'Policy Number',
  'Policy Term Start Date',
  'Policy Term End Date',
  'Effective Date',
];

const carrierNames = {
  TRAVELERS: 'travelers',
  ZURICH: 'zurich',
  CHUBB: 'chubb'
};

const AutoSuggestForNewEntityKey = [
  'First Named Insured',
  'Mailing Address',
  'Policy Term Start Date',
  'Policy Term End Date',
  'Effective Date',
  'Time and Place of Expiry',
  'Policy Number',
  'Carrier Name',
  'Issuing Paper',
  'Commission (Declarations)',
  'Retro / Continuity Date',
  'Policy Form (Claims Made, Occurrence or Discovery Basis)',
  'Premium (including taxes and surcharges) [CGL]',
  'Premium (including taxes and surcharges) [EBL]',
  'Producer Name',
];

const LossRunTab = {
  Summary: 0,
  Details: 1,
};

const AccordFormType = "ACORD Form";
const WcType = "Child Care Application";
const CCType = "Workers Compensation Application";
const SEPType = "Special Events Application";
const LRType = "Loss Run";
const LossRunSummaryTableName = "Loss Run Summary";
const LossRunDetailsTableName = "Loss Run Details";
const HCFAFormType = "HCFA 1500";
const InvoiceType = "Invoice";
const MRType = "Medical Report";
const MVRType = "Market Valuation Report";
const SupplementalType = "Supplemental";
const W9Type = "W9";
const AFBType = "Application for Benefits";
const BinderQuote = "Binder/Quote";
const EndorsementFormsListed = "Endorsement Forms Listed";
const PolicyDocumentType = "Policy Document";
const ExposureType = "Exposure";
const OtherType = "Other";
const MergedType = "Merged";
const PipelineRetryCount = 30;
const MaxCanvasScale = 2;
const MinCanvasScale = 1;
const CanvasScaleFactor = 0.2;
const SearchResultBucketSize = 75;
const ImageLoadBuffer = 4;
const ReadOnlyMode = false;
const TimerShortPushingInterval = 10000; // in milliseconds
const IdleUserTimeout = 100000; // in milliseconds
const WarningTimeout = 20; // in seconds

const DensityCalculationDocument = "Density Calculation Document";
const BordereauxType = "Bordereaux";

const BrowserTypes = ["PDF", "JPG", "JPEG", "PNG", "SVG", "GIF", "XLSX"]; // this xlsx is temporary only for marsh demo
const OfficeFileTypes = ["XLSX", "XLS", "DOC", "DOCX", "PPT", "PPTX"];
const GoogleFileTypes = ["TXT", "XPS", "ZIP", "RAR", "PSD", "TIFF"];
const AdminEmail = 'admin@q.com';

const FileSubmissionTableHeaderProps = [
  {
    name: "description",
    label: "Description",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
  {
    name: "business_case",
    label: "business case",
    infoIcon: false,
    sort: false,
    tooltip: "",
  },
  {
    name: "submission_date",
    label: "submission time",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
  {
    name: "status",
    label: "Status",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
];

const EmailSubmissionTableHeaderProps = [
  {
    name: "from",
    label: "from",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
  {
    name: "subject",
    label: "subject",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
  {
    name: "name",
    label: "name",
    infoIcon: false,
    sort: false,
    tooltip: "",
  },
  {
    name: "submission_date",
    label: "submission time",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
  {
    name: "status",
    label: "Status",
    infoIcon: false,
    sort: true,
    tooltip: "",
  },
];

const ClassificationTypes = [
  {
    key: "Binder/Quote",
    value: "Binder/Quote",
  },
  {
    key: "Broker Slip",
    value: "Broker Slip",
  },
  {
    key: "Policy Document",
    value: "Policy Document",
  },
  {
    key: 'Acord',
    value: 'Acord',
  },
  {
    key: 'Acord 1',
    value: 'Acord 1',
  },
  {
    key: 'Acord 37',
    value: 'Acord 37',
  },
  {
    key: 'Acord 45',
    value: 'Acord 45',
  },
  {
    key: 'Acord 75',
    value: 'Acord 75',
  },
  {
    key: 'Acord 101',
    value: 'Acord 101',
  },
  {
    key: 'Acord 125',
    value: 'Acord 125',
  },
  {
    key: 'Acord 126',
    value: 'Acord 126',
  },
  {
    key: 'Acord 127',
    value: 'Acord 127',
  },
  {
    key: 'Acord 128',
    value: 'Acord 128',
  },
  {
    key: 'Acord 129',
    value: 'Acord 129',
  },
  {
    key: 'Acord 130',
    value: 'Acord 130',
  },
  {
    key: 'Acord 131',
    value: 'Acord 131',
  },
  {
    key: 'Acord 137',
    value: 'Acord 137',
  },
  {
    key: 'Acord 139',
    value: 'Acord 139',
  },
  {
    key: 'Acord 140',
    value: 'Acord 140',
  },
  {
    key: 'Acord 141',
    value: 'Acord 141',
  },
  {
    key: 'Acord 143',
    value: 'Acord 143',
  },
  {
    key: 'Acord 144',
    value: 'Acord 144',
  },
  {
    key: 'Acord 145',
    value: 'Acord 145',
  },
  {
    key: 'Acord 146',
    value: 'Acord 146',
  },
  {
    key: 'Acord 147',
    value: 'Acord 147',
  },
  {
    key: 'Acord 149',
    value: 'Acord 149',
  },
  {
    key: 'Acord 152',
    value: 'Acord 152',
  },
  {
    key: 'Acord 155',
    value: 'Acord 155',
  },
  {
    key: 'Acord 160',
    value: 'Acord 160',
  },
  {
    key: 'Acord 163',
    value: 'Acord 163',
  },
  {
    key: 'Acord 175',
    value: 'Acord 175',
  },
  {
    key: 'Acord 185',
    value: 'Acord 185',
  },
  {
    key: 'Acord 186',
    value: 'Acord 186',
  },
  {
    key: 'Acord 187',
    value: 'Acord 187',
  },
  {
    key: 'Acord 190',
    value: 'Acord 190',
  },
  {
    key: 'Acord 202',
    value: 'Acord 202',
  },
  {
    key: 'Acord 212',
    value: 'Acord 212',
  },
  {
    key: 'Acord 803',
    value: 'Acord 803',
  },
  {
    key: 'Acord 823',
    value: 'Acord 823',
  },
  {
    key: 'Acord 825',
    value: 'Acord 825',
  },
  {
    key: 'Acord 829',
    value: 'Acord 829',
  },
  {
    key: 'Email body',
    value: 'Email body'
  },
  {
    key: 'Loss run',
    value: 'Loss run'
  },
  {
    key: 'SoV',
    value: 'SoV'
  },
  {
    key: 'Density Calculation Document',
    value: 'Density Calculation Document'
  },
  {
    key: 'Bordereaux',
    value: 'Bordereaux',
  },
  {
    key: 'Application form',
    value: 'Application form',
  },
  {
    key: 'Invoice',
    value: 'Invoice',
  },
  {
    key: 'Accountable Care',
    value: 'Accountable Care',
  },
  {
    key: 'Surety',
    value: 'Surety',
  },
  {
    key: 'Cyber App',
    value: 'Cyber App',
  },
  {
    key: 'Proposal Form',
    value: 'Proposal Form',
  },
  {
    key: "Merged",
    value: "Merged",
  },
  {
    key: "Supplemental documents",
    value: "Supplemental documents",
  },
  {
    key: "MVR",
    value: "MVR",
  },
  {
    key: "Custom kv",
    value: "Custom kv",
  },
  {
    key: "Cyber Lob",
    value: "Cyber Lob",
  },
  { 
    key: 'Financial Statement', 
    value: 'Financial Statement' 
  },
  { 
    key: 'Policy Documents', 
    value: 'Policy Documents' 
  },
  { 
    key: 'Questionnaire', 
    value: 'Questionnaire' 
  },
  { 
    key: 'Renewal Application', 
    value: 'Renewal Application' 
  },
  { 
    key: 'Email', 
    value: 'Email' 
  },
  { 
    key: 'Forensic Summary Report', 
    value: 'Forensic Summary Report' 
  },
  { 
    key: 'Statement of work', 
    value: 'Statement of work' 
  },
  {
    key: 'New business Application',
    value: 'New business Application'
  },
  { 
    key: 'Marketing collateral', 
    value: 'Marketing collateral' 
  },
  { 
    key: 'Binder-quote', 
    value: 'Binder-quote' 
  },
  { 
    key: 'Other Doc Type', 
    value: 'Other Doc Type' 
  },
  { 
    key: 'Endorsement', 
    value: 'Endorsement' 
  },
  {
    key: 'Social Engineering Application',
    value: 'Social Engineering Application'
  },
  { 
    key: 'Insurance Application', 
    value: 'Insurance Application' 
  },
  {
    key: 'Supplemental Application',
    value: 'Supplemental Application'
  },
  {
    key: "Diagnostic Report",
    value: "Diagnostic Report",
  },
  {
    key: "Others Unreadable",
    value: "Others Unreadable",
  },
  {
    key: "Bill Document",
    value: "Bill Document",
  },
  {
    key: 'Discharge Summary',
    value: 'Discharge Summary'
  },
  {
    key: "Other",
    value: "Other",
  }
];


const SubClassificationTypes = [
  {
    key: "Policy Document",
    value: "New Policy",
  },
  {
    key: "Policy Document",
    value: "Old Policy",
  },
];

const SubmissionsFilterList = [
  {
    name: 'Submission Time'
  },
  {
    name: 'Triage'
  },
  {
    name: 'Source'
  },
];

const MergeDocument = {
  key: 'Merged',
  value: 'Merged Document',
}

const EmailType = {
  due_diligence: 'Due Diligence',
  table_mismatch: 'Table Mismatch',
}

const FilesSubmissionTableHeaderProps = [

  {
    name: 'sender_name',
    label: 'Sender Name',
    sort: true,
  },
  {
    name: 'description',
    label: 'Submission Description',
    sort: false,
  },
  {
    name: 'lob',
    label: 'Line of Business',
    sort: true,
  },
  {
    name: 'entity',
    label: 'First Name Insured',
    sort: true,
  },
  {
    name: 'submission_date',
    label: 'Submission Time',
    sort: true,
  },
  {
    name: 'triage_score',
    label: 'Triage',
    sort: true,
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
  },
  {
    name: 'source',
    label: 'Source',
    sort: true,
  },
];

const FilesSubmissionTableHeaderPropsReadOnly = [
  {
    name: 'sender_name',
    label: 'Sender Name',
    sort: false,
  },
  {
    name: 'description',
    label: 'Description',
    sort: true,
  },
  {
    name: 'business_case',
    label: 'Business Case',
    sort: false,
  },
  {
    name: 'submission_date',
    label: 'Submission Time',
    sort: true,
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
  },
  {
    name: 'action',
    label: 'Action',
    sort: false,
  },
  {
    name: 'is_deleted',
    label: 'Deleted',
    sort: false,
  },
];

const ExtractionView = {
  Default: "Default",
  Email: "Email",
  LossRun: "LossRun",
};

const RenderView = {
  PDF: "PDF",
  Image: "Image"
};

const HitlStatus = {
  HIDE: 'hide',
  DISABLE: 'disable',
  ENABLE: 'enable',
};

const GroupTooltipValues = [
  {
    groupName: "Named Insured Schedule/Additional Named Insureds",
    tooltipValue:
      "Checks whether the named insured schedule matches across the documents.",
  },
  {
    groupName: "Policy Term on endorsements",
    tooltipValue: "Checks whether the Policy term on declaration page matches the policy term on attached endorsements. In case of any missing form/endorsements, please add the same from 'Policy Forms listed and Description'. ",
  },
  {
    groupName: "Policy Effective Date On Endorsements (if Applicable)",
    tooltipValue: "Checks whether the Policy Effective Date on declaration page matches the policy effective date on attached endorsements. In case of any missing form/endorsements, please add the same from 'Policy Forms listed and Description'. ",
  },
  {
    groupName: "Policy Number on endorsements",
    tooltipValue: "Checks whether the Policy number on declaration page matches the policy term on attached endorsements. In case of any missing form/endorsements, please add the same from 'Policy Forms listed and Description'. ",
  },
  {
    groupName: "Coverage Symbol",
    tooltipValue:
      "3 way comparison showing if the data is present in document or not.",
  },
  {
    groupName: "Policy Forms Attached",
    tooltipValue:
      "Displays all the forms that are listed in the form schedule table and attached in the renewal policy document as Yes; the forms that are listed in the form schedule table but not attached as Not Attached ; all forms that are not listed in the form schedule table but present as attachment to the policy as Not Listed."
  },
  {
    groupName: "Policy Forms listed & Description",
    tooltipValue: "Checks whether all policy forms listed match across the documents.",
  },
  {
    groupName: "Binder Forms Listed",
    tooltipValue: "Checks whether all the forms listed on the binder are attached to the policy. In case of any missing form/endorsements, please add the same from 'Policy Forms listed and Description' "
  },
  {
    groupName: "Location Schedule",
    tooltipValue:
      "Checks whether the location schedule matches across the documents.",
  },
  {
    groupName: "Additional Interests / Insured / Loss Payees",
    tooltipValue:
      "Checks whether additional interests/insured information matches across the documents.",
  },
];

const DownloadTypes = [
  {
    key: "Download as JSON",
    value: "Download as JSON",
    hidden: false,
    selected: false
  },
  {
    key: "Download as XLSX",
    value: "Download as XLSX",
    hidden: false,
    selected: false
  },
  {
    key: "Download as CSV",
    value: "Download as CSV",
    hidden: false,
    selected: false
  },
  {
    key: "Download as XML",
    value: "Download as XML",
    hidden: false,
    selected: false
  },
];

const Download_As_Json = "Download as JSON";
const Download_As_Xlsx = "Download as XLSX";
const Download_As_Csv = "Download as CSV";
const Download_As_Xml = "Download as XML";

export const screenTypes = {
  COMPARISON_VIEW: 'comparison',
  EXTRACTION_VIEW: 'extraction',
  VALIDATION_VIEW: 'validation',
}

const exportQuestions = {
  QUESTION_FIRST_NAMED_INSURED: "Does the First Named Insured listed on the binder match the Named Insured listed on the policy?",
  QUESTION_MAILING_ADDRESS: "Does the Mailing Address listed on the binder match the Mailing Address listed on the policy?",
  QUESTION_NAMED_INSURED_SCHEDULE: "Does the Named Insured(s) Schedule / Additiona Named insured  Schedule list in the submission and/or on the binder match the Named Insured(s) Schedule/Additional Named Insureds listed on the policy?",
  QUESTION_POLICY_TERM: "Does the Policy Term listed on the binder match the Policy Term listed on the policy?",
  QUESTION_POLICY_FORMS_LISTED_AND_DESCRIPTION: "Are all forms listed on the policy forms schedule listed on the binder? Please only note forms that amend covererage",
  QUESTION_BINDER_FORMS_LISTED: "Are all forms listed on the binder attached to the policy?",
  QUESTION_POLICY_FORMS_ATTACHED: "Are all forms listed on the policy forms schedule attached to the policy?",
  QUESTION_TIME_AND_PLACE_OF_EXPIRY: "Does the Time and Place of Expiry (Time Zone specific) on binder match the Time and Place of Expiry (Time Zone specific) on the policy declarations page?  (generally found after policy term within verbiage)",
  QUESTION_POLICY_NUMBER: "Does the Policy Number listed on the binder match the Policy Number listed on the policy?",
  QUESTION_CARRIER_NAME: "Does the carrier name / issuing paper on the binder match the carrier name / issuing paper listed on policy declarations page?",
  QUESTION_ISSUING_PAPER: "Does the carrier name / issuing paper on the binder match the carrier name / issuing paper listed on policy declarations page?",
  QUESTION_PRODUCER_NAME: "Does the Producer Name listed on the binder match the Producer Name listed on the policy?",
  QUESTION_LIMITS_SUBLIMITS: "Do the all of the limits and sublimits on the binder match all of the the limits in the policy?",
  QUESTION_RETENTIONS_DEDUCTIBLES: "If applicable, do the deductibles/retention in the binder match the deductibles in the policy?",
  QUESTION_PREMIUM_INCLUDING_TAXES_AND_SURCHARGES: "Does the Premium listed on the binder match the Premium listed on the policy? This is inclusive of all taxes, surcharges, fees and TRIA premium.",
  QUESTION_CURRENCY: "Does currency listed on binder match the currency found within the policy/endorsements?",
  QUESTION_COMMISSION_DECLARATIONS: "Does the Commission listed on the binder match the Commission listed on the policy?",
  QUESTION_DAYS_NOTICE_OF_CANCELLATION: "Does the Days Notice Of Cancellation on binder match Days Notice Of Cancellation found within the policy language/endorsements?",
  QUESTION_RETRO_CONTINUITY_DATE: "Does the Retro / Continuity Date in the binder match what is on the policy?",
  QUESTION_LOCATION_SCHEDULE: "Does the Location Schedule listed in the submission and/or on the binder match the Location Schedule listed on the policy?",
  QUESTION_ADDITIONAL_INTERESTS: "If there are any Additional Interests/Loss Payees listed on the binder or exposure information, do they match the Additonal Interests/Loss Payees listed on the policy?  If listed on renewal policy or exposure information, do they match expiring policy?",
  QUESTION_POLICY_FORM_CLAIMS_MADE_OCCURRENCE_OR_DISCOVERY_BASIS: "Is the policy written on a claims made or an occurrence basis?",
  QUESTION_POLICY_TERM_ON_ENDORSEMENTS: "Does the policy term on policy dec page match the policy term on attached endorsements?",
  QUESTION_POLICY_EFFECTIVE_DATE_ON_ENDORSEMENTS: "Does effective date on policy dec page match the effective date on attached endorsements?",
  QUESTION_POLICY_NUMBER_ON_ENDORSEMENTS: "Does the policy number on the policy dec page match the policy number listed on attached endorsements?",
}



const extractionSchema = {
  key: "",
  value: "",
  display_key: "",
  coverage: "",
  sub_coverage: "",
  sub_limit: "",
  location_number: "",
  building_number: "",
  address: "",
  occupancy: "",
  key_bounding_box: [0, 0, 0, 0],
  value_bounding_box: [0, 0, 0, 0],
  endorsement: "",
  coverage_bounding_box: [0, 0, 0, 0],
  sub_coverage_bounding_box: [0, 0, 0, 0],
  sub_limit_bounding_box: [0, 0, 0, 0],
  location_number_bounding_box: [0, 0, 0, 0],
  building_number_bounding_box: [0, 0, 0, 0],
  address_bounding_box: [0, 0, 0, 0],
  occupancy_bounding_box: [0, 0, 0, 0],
  key_confidence_score: 1,
  value_confidence_score: 1,
  coverage_confidence_score: 1,
  sub_coverage_confidence_score: 1,
  sub_limit_confidence_score: 1,
  hitl_confirmed: true,
  is_deleted: false,
};


const PremiumIncludingTaxesAndSurcharges = ["premium (including taxes and surcharges) [ebl]", "premium (including taxes and surcharges) [cgl]", "total premium"];
const PolicyTerm = ['policy term start date', 'policy term end date'];


const DeleteRowToast = "Row Delete Successfully";
const BulkDeleteRowToast = "Rows Delete Successfully";
const RowDeleteFailedToast = "Failed To Delete Row";
const BulkRowDeleteFailedToast = "Failed To Delete Rows";
const ValueUpdateToast = "Value Updated Successfully";
const ValueUpdateFailedToast = "Failed To Update Value";
const EntityAddedToast = "Entity Added Successfully";
const EntityAddedFailedToast = "Failed To Add Entity";
const KeyUpdateToast = "Key Updated Successfully";
const KeyUpdateFailedToast = "Failed To Update Key";
const ComparisonView = "Comparison View"
const Reviewed = "reviewed"
const SortingOrder = {
  sort: 'sort',
  default: 'default'
}

export const CanShowExtraction = true;
export const CanShowComparison = true;
export const CanShowValidation = true;
export const CanShowEnrichment = true;

export const PAGE_OFFSET = 50;
export {
  SortingOrder,
  Reviewed,
  BulkDeleteRowToast,
  BulkRowDeleteFailedToast,
  DeleteRowToast,
  ValueUpdateToast,
  ValueUpdateFailedToast,
  KeyUpdateFailedToast,
  RowDeleteFailedToast,
  EntityAddedFailedToast,
  KeyUpdateToast,
  EntityAddedToast,
  ComparisonView,
  SubmissionStatus,
  AttachmentStatus,
  FileSubmissionTableHeaderProps,
  FilesSubmissionTableHeaderPropsReadOnly,
  EmailSubmissionTableHeaderProps,
  BrowserTypes,
  OfficeFileTypes,
  GoogleFileTypes,
  ClassificationTypes,
  SubClassificationTypes,
  AccordFormType,
  WcType,
  CCType,
  SEPType,
  LRType,
  PipelineRetryCount,
  LossRunTab,
  LossRunSummaryTableName,
  LossRunDetailsTableName,
  HCFAFormType,
  InvoiceType,
  MRType,
  MVRType,
  SupplementalType,
  W9Type,
  AFBType,
  ExtractionView,
  MaxCanvasScale,
  MinCanvasScale,
  CanvasScaleFactor,
  BinderQuote,
  PolicyDocumentType,
  EndorsementFormsListed,
  ExposureType,
  OtherType,
  MergedType,
  SearchResultBucketSize,
  FilesSubmissionTableHeaderProps,
  BusinessCase,
  LOBSummary,
  CarrierTypes,
  GroupTooltipValues,
  SectionNames,
  GroupNames,
  DownloadTypes,
  keysMapping,
  Download_As_Json,
  Download_As_Xlsx,
  Download_As_Csv,
  Download_As_Xml,
  exportQuestions,
  AutoSuggestForNewEntityKey,
  KeysForReviseRules,
  extractionSchema,
  ImageLoadBuffer,
  PolicyTermKeys,
  carrierNames,
  PremiumIncludingTaxesAndSurcharges,
  PolicyTerm,
  RenderView,
  ReadOnlyMode,
  TimerShortPushingInterval,
  IdleUserTimeout,
  WarningTimeout,
  HitlStatus,
  SubmissionsFilterList,
  EmailType,
  MergeDocument,
  DensityCalculationDocument,
  BordereauxType,
  AdminEmail,
  DeploymentStatus
};

import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';

function ProtectedOutlet() {
  const { user, loaded } = useUserAuth();
  const location = useLocation();

  return (
    <React.Fragment>
      {/* original */}
      {
        loaded ?
          user ? <Outlet /> : <Navigate to="/signin" state={{ from: location }} /> :
          'Loading....'
      }
    </React.Fragment>
  );
}

export default ProtectedOutlet;

import React from 'react';
import DociphiLogo from "../../../../assets/Header/dociphi-header-logo.svg";
import Logout from "../../../../assets/Header/logout.svg";
import styles from "./Header.module.css";
import { useUserAuth } from '../../../../context/UserAuthContext';
import useAxios from '../../../../hooks/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const { logOut } = useUserAuth();
  const axiosPrivate = useAxios();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axiosPrivate.post('/v1/identity/tokens/revoke');
      try {
        await logOut();
        navigate("/signin", { state: { from: location } });
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.header_container}>
      <div>
        <img
          src={DociphiLogo}
          alt='dociphi-logo'
        />
      </div>
      <div onClick={handleLogout} aria-hidden style={{ cursor: 'pointer' }}>
        <img
          src={Logout}
          alt='logout'
        />
      </div>
    </div>
  )
}

export default Header

import { ExtractionActionTypes } from "../constants/extractionActionTypes";

const initialState = {
  selectedDocumentId: '',
  extraction: {}
}

const extractionReducer = (state = initialState, { type, payload }) => {
  switch(type) {
    case ExtractionActionTypes.SET_SELECTED_EXTRACTION:
      return {...state, extraction: payload}

      case ExtractionActionTypes.REMOVE_SELECTED_EXTRACTION:
        return {...state, extraction: {}}

    case ExtractionActionTypes.SET_SELECTED_DOCUMENT_ID:
      return {...state, selectedDocumentId: payload};

    case ExtractionActionTypes.REMOVE_SELECTED_DOCUMENT_ID:
      return {...state, selectedDocumentId: ''};

    default:
      return state;
  }
};

export default extractionReducer;
import React from "react";
import { Routes as ViewsRoutes, BrowserRouter, Route, Navigate } from "react-router-dom";

import { Error, Unauthorized } from "../components";
import MainLayout from "../layouts/main/MainLayout";
import ProtectedOutlet from "./ProtectedOutlet";
import { UserAuthContextProvider } from "../context/UserAuthContext";

const LazySignin = React.lazy(() => import("../views/signin/Signin"));
const LazyLandingPage = React.lazy(() => import("../views/landing-page/LandingPage"));

function Routes() {
  return (
    <BrowserRouter>
      <UserAuthContextProvider>
        <ViewsRoutes>
          {/* Public Routes */}
          <Route path="*" element={<Navigate to="/signin" />} />
          <Route path="error" element={<Error />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          <Route
            path="/signin"
            element={
              <React.Suspense fallback={<>...</>}>
                <LazySignin />
              </React.Suspense>
            }
          />

          {/* Protected Routes */}
          <Route element={<ProtectedOutlet />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Navigate to="/home" />} />
              <Route
                path="/home"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <LazyLandingPage />
                  </React.Suspense>
                }
              />
            </Route>
          </Route>
        </ViewsRoutes>
      </UserAuthContextProvider>
    </BrowserRouter>
  );
}
export default Routes;
